export const Props = Symbol('Props')
export const StylesStoreSymbol = Symbol('StylesStore')
export const Structure = Symbol('Structure')
export const Comps = Symbol('Comps')
export const CompActionsSym = Symbol('CompActions')
export const StructureAPI = Symbol('StructureAPI')
export const ExportsStoreSymbol = Symbol('Exports')
export const StateRefsStoreSymbol = Symbol('StateRefs')
export const MaterializedStoreSymbol = Symbol('MaterializedStore')
export const CatharsisMegaStoreSymbol = Symbol('CatharsisMegaStore')
